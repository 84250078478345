<template>
  <div>
    <div class="content">
      <span @click="data.expanded=!data.expanded" class="pre_span" :class="getClass(data)"></span>
      <span @click="nodeClick(data)" @dblclick="data.expanded=!data.expanded" class="label"
            :class="{select: data.folderId===selectData.folderId}">{{data.folderName}}</span>
    </div>
    <div v-if="data.expanded&&checkChildren(data)" class="sub_box">
      <Node v-for="(node, index) in data.sub" :data="node" :key="node.id" @node-click="nodeClick"
            :indexInfo="{total: data.sub.length, index: index+1}" :selectData="selectData"
            @expanded="data.expanded = true"></Node>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Node',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    indexInfo: {
      type: Object,
      default () {
        return { total: 0, index: 0 }
      }
    },
    selectData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    selectData (val) {
      if (this.data.folderId === val.folderId) {
        this.data.expanded = true
        this.$emit('expanded', true)
      }
    }
  },
  methods: {
    getClass (data) {
      const { expanded } = data
      let className = ''
      if (this.indexInfo.index < this.indexInfo.total) {
        className += 'center_'
      } else {
        className += 'bottom_'
      }
      const hasSub = this.checkChildren(data)
      if (hasSub) {
        className += expanded ? 'open' : 'close'
      } else {
        className += 'docu'
      }
      return className
    },
    nodeClick (data) {
      this.$emit('node-click', data)
    },
    checkChildren ({ sub }) {
      return !!(sub && sub.length)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  .label {
    padding: 1px 3px 0 1px;
    margin: 0;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    color: #333;
    background-color: transparent;
    text-decoration: none;
    vertical-align: top;
    display: inline-block;
    &.select {
      background-color: #EBF1F9;
      color: #3089DC;
      height: 24px;
      opacity: 0.8;
    }
  }
  .pre_span {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    border: 0 none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: url(https://img.alicdn.com/tfs/TB1wZpDnBTH8KJjy0FiXXcRsXXa-296-296.png);
    background-size: 148px 148px;
    &.bottom_docu {
      background-position: -8px -59px;
    }
    &.center_docu {
      background-position: -8px -35px;
    }
    &.center_open {
      background-position: -97px -35px;
    }
    &.center_close {
      background-position: -55px -35px;
    }
    &.bottom_close {
      background-position: -55px -59px;
    }
    &.bottom_open {
      background-position: -97px -59px;
    }
  }
}

.sub_box {
  padding-left: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAYAAAB/qH1jAAAAHklEQVR42mXIwQkAAACCwPYf1DWM3j2Ew6gBXPONAsvNGrmG5XZfAAAAAElFTkSuQmCC) 11px 0 repeat-y;
  background-size: 2px 2px;
}
</style>
